export const useGlobalStore = defineStore("globalStore", () => {
  const menuOpen = ref(false);
  const toolsOpen = ref(false);

  const toggleMenu = () => {
    menuOpen.value = !menuOpen.value;
  };

  const setMenu = (value: boolean) => {
    menuOpen.value = value;
  };

  const toggleTools = () => {
    toolsOpen.value = !toolsOpen.value;
  };

  return { menuOpen, toolsOpen, toggleMenu, setMenu, toggleTools };
});
